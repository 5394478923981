<template>
  <page-header text="Order Items"> </page-header>
  <table-component
    :retrieve-table-data-function="retrieveTableData"
    :update-on-change="updateKey"
    :unique-key="'id'"
    :process-field-function="processFieldFunction"
    :click-function="openItemDialog"
    :hidden-fields="[
      'updated',
      'is_expanded',
      'expanded_from',
      'customization_has_been_performed',
      'unresolved_sku',
      'has_fault',
      'notes',
      'order_item_status_counts',
      'has_design_file'
    ]"
    :small-screen-hidden-fields="[
      'order',
      'notes',
      'created',
      'post processing notes',
      'custom text',
      'manufacture notes'
    ]"
    :row-end-buttons="rowEndButtons"
    :row-end-top-buttons="rowEndTopButtons"
    :display-check-boxes="true"
    :long-text-field-keys="['post_processing_notes', 'notes']"
    :filters-prop="filters"
    :highlighted-rows="faultedItems"
    :highlighted-icon="{
      iconComponent: ExclamationTriangleIcon,
      class: 'text-red-700 w-6 h-6 mr-[-0.5rem] ml-[-0.25rem] sm:ml-[-0.5rem]',
      tooltip: 'Item Has Previously Been Marked As Faulted'
    }"
    :default-filter="{
      objectPropertyKeyName: filters[0].objectPropertyKeyName,
      objectPropertyValue: filters[0].propertyFilterValues[0]
    }"
    :show-group-rows-by-filter-check-box="true"
    :default-active-column="'created'"
    :default-descending="true"
    :process-column-header-function="(h: string) => (h === 'id' ? 'ID' : h)"
    :collapsable-rows-icon-object="tableCollapsableRowIconObject"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { type Material, SkuDesignTypeEnum } from '@/api'
import TableComponent from '@/components/TableComponent.vue'
import PageHeader from '@/components/PageHeader.vue'
import StatusLabelComponent from '@/components/StatusLabels/BaseStatusLabelComponent.vue'
import { errorHandler } from '@/services/ErrorHandler'
import {
  AbortablePromise,
  activeOrderItemStatuses,
  faultableOrderItemStatuses,
  Filter,
  getEnumAsArray,
  LocalSku,
  processHumanReadableDate,
  LocalOrderItemStatusEnum,
  capitalizeFirstLetters,
  CollapsableRowGroupIcon
} from '@/services/DataServices'
import type { DataSet, TableRowEndButton } from '@/services/DataServices'
import {
  ChatBubbleLeftEllipsisIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  ExclamationTriangleIcon,
  PaintBrushIcon,
  PencilSquareIcon,
  WrenchScrewdriverIcon,
  RectangleGroupIcon,
  XMarkIcon,
  ArrowDownTrayIcon
} from '@heroicons/vue/24/outline'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid'
import {
  confirmDialog,
  createUpdateditemsListener,
  newDialog,
  newErrorDialog,
  showSpinner,
  createUpdateditemListener,
  createUpdatedOrderListener,
  itemsUpdated,
  runFunctionWithLoadingSpinner,
  confirmNumberDialog
} from '@/composables'
import { api, LocalOrderItem } from '@/services/DataServices'
import TableIconComponent from '@/components/TableIconComponent.vue'
import { getGlobalUpdatingFlag, setGlobalUpdatingFlag } from '@/main'

export default defineComponent({
  name: 'ItemsView',
  data() {
    return {
      tableCollapsableRowIconObject: this.getCollapsableRowsNotesIconObject(),
      updateKey: 0 as number,
      skus: [] as LocalSku[],
      materials: [] as Material[],
      faultedItems: [] as LocalOrderItem[],
      abortablePromise: new AbortablePromise(),
      filters: [
        new Filter(
          'status',
          ['Active Statuses', ...getEnumAsArray(LocalOrderItemStatusEnum)],
          0,
          undefined,
          1
        )
      ] as Filter[],
      rowEndTopButtons: [
        {
          label: '',
          tooltip: 'Update Status for all selected Items',
          getToolTip: (items: LocalOrderItem[]) => {
            const statusObject = this.statusesAreTheSame(items)
            if (!statusObject.sameStatuses) {
              return 'Cannot update multiple Items with different statuses'
            } else if (items[0].hasStatusReviewBlocked) {
              return 'Cannot update Items in an Error State'
            } else if (this.containsFinalizedItems(items)) {
              return 'Cannot update Completed or Canceled Items'
            } else if (
              statusObject.includesItemRequiringCustomization &&
              statusObject.includesItemNotRequiringCustomization
            ) {
              return 'Some Items Still Require Customization'
            } else if (items[0].hasStatusUserReview) {
              return 'Cannot update Items that require review'
            } else if (statusObject.includesItemRequiringCustomization) {
              return (
                'Mark Items as Customized and Progress to "' +
                items[0].nextStatus().replace(/_/g, ' ') +
                '"'
              )
            } else if (statusObject.includesSkuResolutionFailed) {
              return 'Batch Run Sku Resolution for ' + items.length + ' items'
            } else if (statusObject.includesStockCheckItem) {
              return 'Verify Stock Available For Items'
            } else {
              return 'Progress Item Statuses to "' + items[0].nextStatus().replace(/_/g, ' ') + '"'
            }
          },
          obscure: (items: LocalOrderItem[]) => {
            const statusObject = this.statusesAreTheSame(items)
            return (
              !statusObject.sameStatuses ||
              (statusObject.includesItemRequiringCustomization &&
                statusObject.includesItemNotRequiringCustomization) ||
              this.containsFinalizedItems(items) ||
              items[0].hasStatusReviewBlocked ||
              items[0].hasStatusUserReview
            )
          },
          getIcon: (items: LocalOrderItem[]) => {
            const statusObject = this.statusesAreTheSame(items)
            if (
              !statusObject.sameStatuses ||
              items[0].hasStatusUserReview ||
              (statusObject.includesItemRequiringCustomization &&
                statusObject.includesItemNotRequiringCustomization)
            ) {
              return XMarkIcon
            } else {
              return this.displaySetInStockButton(items[0])
                ? ClipboardDocumentCheckIcon
                : this.displayStagedForNestingButton(items[0])
                  ? RectangleGroupIcon
                  : this.displayProgressButton(items[0])
                    ? PaintBrushIcon
                    : this.displayItemCompleteButton(items[0])
                      ? CheckIcon
                      : this.displayItemPostProcessingCompleteButton(items[0])
                        ? CheckIcon
                        : this.displayCustomizedButton(items[0])
                          ? PencilSquareIcon
                          : this.displaySkuResolutionButton(items[0])
                            ? WrenchScrewdriverIcon
                            : XMarkIcon
            }
          },
          action: this.updateStatusForSelectedRows,
          displayCheckFunction: this.displayBatchStatusUpdateButton
        },
        {
          label: '',
          tooltip: '',
          getToolTip: (items: LocalOrderItem[]) => {
            const statusObject = this.statusesAreTheSame(items)
            if (!statusObject.sameStatuses) {
              return 'Cannot update multiple Items with different statuses'
            } else {
              return 'Add a fault for ' + items.length + ' items.'
            }
          },
          icon: ExclamationTriangleIcon,
          action: this.addFaults,
          displayCheckFunction: this.displayFaultsButton
        },
        {
          label: '',
          tooltip: '',
          getToolTip: (items: LocalOrderItem[]) => {
            return this.obscureDownloadDesignFilesButton(items)
              ? 'Some Items Do Not have design files available for download.'
              : 'Download Design Files for ' + items.length + ' items.'
          },
          icon: ArrowDownTrayIcon,
          action: this.downloadDesignFiles,
          displayCheckFunction: this.displayDownloadDesignFilesButton,
          class: 'hidden sm:flex',
          obscure: this.obscureDownloadDesignFilesButton
        }
      ] as TableRowEndButton[],
      rowEndButtons: [
        {
          label: '',
          tooltip: 'Verify Stock Available For Item',
          icon: ClipboardDocumentCheckIcon,
          action: this.itemStockAvailable,
          displayCheckFunction: this.displaySetInStockButton,
          name: 'Verify Stock'
        },
        {
          label: '',
          tooltip: 'Item Has Been Staged For Nesting',
          icon: RectangleGroupIcon,
          action: this.changeStatusToStagedForNesting,
          displayCheckFunction: this.displayStagedForNestingButton,
          name: 'Set Staged For Nesting'
        },
        {
          label: '',
          tooltip: 'Progress to Post Processing',
          icon: PaintBrushIcon,
          action: this.progressToPostProcessing,
          displayCheckFunction: this.displayProgressButton,
          name: 'Set Post Processing'
        },
        {
          label: '',
          tooltip: 'Item Manufacture Complete',
          icon: CheckIcon,
          action: this.progressToReadyForDispatch,
          displayCheckFunction: this.displayItemCompleteButton,
          name: 'Set Manufacture Complete'
        },
        {
          label: '',
          tooltip: 'Item Post-Processing Complete',
          icon: CheckIcon,
          action: this.progressToPostProcessed,
          displayCheckFunction: this.displayItemPostProcessingCompleteButton,
          name: 'Set Post-Processing Complete'
        },
        {
          label: '',
          tooltip: 'Record A Fault For This Item',
          icon: ExclamationTriangleIcon,
          action: this.addFault,
          displayCheckFunction: this.displayFaultButton,
          name: 'Record Fault'
        },
        {
          label: '',
          tooltip: 'Custom Design Applied To Item',
          icon: PencilSquareIcon,
          action: this.customizationHasBeenPerformed,
          displayCheckFunction: this.displayCustomizedButton,
          name: 'Set Customized'
        },
        {
          label: '',
          tooltip: 'Imported SKU Resolution Failed',
          icon: WrenchScrewdriverIcon,
          action: this.resolveSkuResolutionFailure,
          displayCheckFunction: this.displaySkuResolutionButton,
          name: 'Resolve SKU Conflict'
        },
        {
          label: '',
          tooltip: 'Download Design File',
          icon: ArrowDownTrayIcon,
          action: this.downloadDesignFile,
          displayCheckFunction: this.displayDownloadDesignFileButton,
          name: 'Download Design',
          class: 'hidden sm:flex'
        }
      ] as TableRowEndButton[]
    }
  },
  methods: {
    ExclamationTriangleIcon,
    triggerTableRefresh() {
      if (!getGlobalUpdatingFlag()) {
        this.updateKey++
      }
    },
    openItemDialog(orderItem: LocalOrderItem, skuResolution: boolean = false) {
      api.getItem(orderItem.id as number).then((item) => {
        newDialog(
          'viewItemDialog',
          {
            orderItem: item,
            skuResolution: skuResolution,
            rowEndButtons: this.rowEndButtons
          },
          false,
          true
        )
      })
    },
    openBatchSkuResolutionDialog(orderItems: LocalOrderItem[]) {
      newDialog('viewBatchSkuResolutionDialog', { orderItems: orderItems })
    },
    openFaultDialog(orderItem: LocalOrderItem | LocalOrderItem[]) {
      newDialog(
        'viewFaultDialog',
        { orderItem: orderItem as LocalOrderItem | LocalOrderItem[] },
        true,
        true
      )
    },
    openStockCheckDialog(orderItem: LocalOrderItem, orderItemSkuName?: string) {
      newDialog(
        'viewStockCheckDialog',
        { orderItem: orderItem as LocalOrderItem, orderItemSkuName: orderItemSkuName },
        true,
        true
      )
    },
    openBatchStockCheckDialog(orderItems: LocalOrderItem[]) {
      newDialog('viewStockCheckDialog', { orderItems: orderItems }, true, true)
    },
    async retrieveTableData(
      maxItems: number,
      startIndex: number,
      stringFilter?: string,
      orderBy?: string,
      filterObjectArray?: Filter[]
    ): Promise<DataSet<LocalOrderItem>> {
      const statusFilter: string | LocalOrderItemStatusEnum[] | undefined =
        filterObjectArray
          ?.find((filter) => filter.objectPropertyKeyName === 'status')
          ?.currentFilter() || undefined

      let materialFilter: string | undefined =
        filterObjectArray
          ?.find((filter) => filter.objectPropertyKeyName === 'material')
          ?.currentFilter() || undefined

      const func = async () =>
        await api.getManufacturableItemsSet(
          maxItems,
          startIndex,
          stringFilter,
          orderBy,
          statusFilter === 'Active Statuses' ? activeOrderItemStatuses : statusFilter,
          materialFilter
        )

      const next = async (
        setArray: DataSet<LocalOrderItem>[]
      ): Promise<DataSet<LocalOrderItem>> => {
        const materialSet: DataSet<Material> = (setArray.find((s) => s.modelName === 'materials') ||
          []) as DataSet<Material>
        const itemModelSet: DataSet<LocalOrderItem> | undefined = setArray.find(
          (set) => set.modelName === 'order_items'
        )
        this.materials = materialSet.data.map((m) => {
          return { id: m.id, label: m.label }
        })
        this.updateMaterialsFilter(materialFilter)
        const itemSet: DataSet<LocalOrderItem> = {
          data: itemModelSet?.data || [],
          totalNum: itemModelSet?.totalNum || 0
        }
        this.faultedItems = itemSet?.data?.filter(
          (i: LocalOrderItem) =>
            i.has_fault && i.status && faultableOrderItemStatuses.includes(i.status)
        )
        await this.retrieveRelevantSkus(itemSet.data)
        itemSet?.data?.forEach((item: LocalOrderItem) =>
          item.setMaterial(this.skus, this.materials)
        )
        return itemSet
      }
      return await this.abortablePromise.resetAndExecute(func, next)
    },
    getDesignType(item: LocalOrderItem): SkuDesignTypeEnum | undefined {
      try {
        return this.skus.find((sku) => sku.id === item.sku)?.design_type as SkuDesignTypeEnum
      } catch (e) {
        errorHandler(e)
      }
    },
    displayBatchStatusUpdateButton(items: LocalOrderItem[]) {
      return (
        items.length > 1 &&
        (!items[0]?.hasStatusReadyForDispatch || !this.statusesAreTheSame(items).sameStatuses)
      )
    },
    displayFaultButton(item: LocalOrderItem) {
      return (
        item.hasStatusStagedForNesting ||
        item.hasStatusAwaitingPostProcessing ||
        item.hasStatusReadyForDispatch
      )
    },
    displayFaultsButton(items: LocalOrderItem[]) {
      return (
        items.length > 1 &&
        this.statusesAreTheSame(items).sameStatuses &&
        this.displayFaultButton(items[0])
      )
    },
    displayDownloadDesignFilesButton(items: LocalOrderItem[]) {
      return items && items.length > 1
    },
    obscureDownloadDesignFilesButton: (items: LocalOrderItem[]) => {
      return !items.every((item) => item?.has_design_file)
    },
    displayStagedForNestingButton(item: LocalOrderItem) {
      const awaitManuf: boolean = item.hasStatusAwaitingManufacturing
      const designType: SkuDesignTypeEnum | undefined = this.getDesignType(item)
      const customized: boolean =
        item.customization_has_been_performed !== undefined && item.customization_has_been_performed
      return (
        awaitManuf &&
        ((designType === SkuDesignTypeEnum.Variable && customized) ||
          designType !== SkuDesignTypeEnum.Variable)
      )
    },
    displaySetInStockButton(item: LocalOrderItem) {
      return item.hasStatusStockCheck || item.hasStatusAwaitingStock
    },
    displayCustomizedButton(item: LocalOrderItem) {
      return (
        this.getDesignType(item) === SkuDesignTypeEnum.Variable &&
        item.hasStatusAwaitingManufacturing &&
        !item.customization_has_been_performed
      )
    },
    displaySkuResolutionButton(item: LocalOrderItem) {
      return item.hasStatusSkuResolutionFailed
    },
    // prettier-ignore
    displayDownloadDesignFileButton(item: LocalOrderItem) { // eslint-disable-line
      return item?.has_design_file === true
    },
    displayProgressButton(item: LocalOrderItem) {
      return item.hasStatusStagedForNesting && item.requires_post_processing
    },
    displayItemPostProcessingCompleteButton(item: LocalOrderItem) {
      // return item.hasStatusAwaitingPostProcessing && item.requires_post_processing
      return item.hasStatusAwaitingPostProcessing
    },
    displayItemCompleteButton(item: LocalOrderItem) {
      return item.hasStatusStagedForNesting && !item.requires_post_processing
    },
    addFault(item: LocalOrderItem) {
      this.openFaultDialog(item)
    },
    addFaults(items: LocalOrderItem[]) {
      this.openFaultDialog(items)
    },
    downloadDesignFiles(items: LocalOrderItem[]) {
      if (this.obscureDownloadDesignFilesButton(items)) {
        newErrorDialog(
          'Design File Error',
          'Some Items Do Not have design files available for download.'
        )
        return
      }
      const label: string = 'Download Confirmation'
      const message: string = 'Compress and Download ' + items.length + ' Design Files?'
      confirmDialog(label, message).then((confirmed: boolean) => {
        if (confirmed) api.downloadDesignFiles(items)
      })
    },
    async changeStatusToStagedForNesting(item: LocalOrderItem) {
      // Customization applies to all "item status counts" / "products", as they use the same design file
      await runFunctionWithLoadingSpinner(() => item.stagedForNesting())
    },
    async progressToPostProcessing(item: LocalOrderItem) {
      // Will set to AwaitingPostProcessing if item.requires_post_processing is true
      await runFunctionWithLoadingSpinner(() => item.readyForDispatch())
    },
    async progressToPostProcessed(item: LocalOrderItem) {
      if (item.hasCountGreaterThan(1))
        await this.progressWithCountConfirmation(
          item,
          LocalOrderItemStatusEnum.AwaitingPostProcessing
        )
      else await runFunctionWithLoadingSpinner(() => item.postProcessed())
    },
    async customizationHasBeenPerformed(item: LocalOrderItem) {
      await runFunctionWithLoadingSpinner(() => item.customizationPerformed())
    },
    async progressToReadyForDispatch(item: LocalOrderItem) {
      // After cutting items that are either complete or require post-processing,
      // both should call item.provision() but the icon on the button should depend on
      // requires_post_processing (progress to post processing (paintbrush icon),
      // or manufacture complete (check icon))
      const frmSts = LocalOrderItemStatusEnum.StagedForNesting
      if (item.hasCountGreaterThan(1)) await this.progressWithCountConfirmation(item, frmSts)
      else await runFunctionWithLoadingSpinner(() => item.progress(frmSts))
    },
    async progressWithCountConfirmation(item: LocalOrderItem, frmStatus: LocalOrderItemStatusEnum) {
      const label: string = 'Progress Products For Item ' + item.id
      const statStr: string = capitalizeFirstLetters(item.nextStatus(frmStatus).replace(/_/g, ' '))
      const message: string = 'Progress the following number of products to ' + statStr + ':'
      const r = await confirmNumberDialog(label, message, item.countAsNumber)
      if (r.confirmed) await runFunctionWithLoadingSpinner(() => item.progress(frmStatus, r.count))
    },
    async changeStatusToAwaitingStock(item: LocalOrderItem) {
      await runFunctionWithLoadingSpinner(() => item.awaitingStock())
    },
    itemStockAvailable(item: LocalOrderItem) {
      const itemSkuName: string | undefined = this.skus.find((s) => s.id === item.sku)?.label
      this.openStockCheckDialog(item, itemSkuName)
    },
    containsFinalizedItems(items: LocalOrderItem[]): boolean {
      return items.some((item: LocalOrderItem) => item.isFinalized)
    },
    statusesAreTheSame(items: LocalOrderItem[]): {
      includesItemRequiringCustomization: boolean
      includesItemNotRequiringCustomization: boolean
      includesReadyForDispatchItem: boolean
      includesStockCheckItem: boolean
      includesSkuResolutionFailed: boolean
      sameStatuses: boolean
    } {
      let statusObject = {
        includesItemRequiringCustomization: false,
        includesItemNotRequiringCustomization: false,
        includesReadyForDispatchItem: false,
        includesStockCheckItem: false,
        includesSkuResolutionFailed: false,
        sameStatuses: true
      }
      items.forEach((item: LocalOrderItem) => {
        if (
          this.getDesignType(item) === SkuDesignTypeEnum.Variable &&
          item.customization_has_been_performed === false
        ) {
          statusObject.includesItemRequiringCustomization = true
        } else {
          statusObject.includesItemNotRequiringCustomization = true
        }
        statusObject.includesSkuResolutionFailed = item.hasStatusSkuResolutionFailed
        statusObject.includesStockCheckItem = item.hasStatusStockCheck
        statusObject.includesReadyForDispatchItem = item.hasStatusReadyForDispatch
        if (item.status !== items[0].status) {
          statusObject.sameStatuses = false
        }
      })
      return statusObject
    },
    async updateStatusForSelectedRows(items: LocalOrderItem[]) {
      try {
        if (!items || items.length < 1 || items.some((i) => i.status === undefined)) {
          newErrorDialog(
            'Status Update Error',
            'Some Item Statuses Are Not Correctly Set, Cannot Progress.'
          )
          return
        }
        let statusObject = this.statusesAreTheSame(items)
        if (!statusObject.sameStatuses) {
          newErrorDialog(
            'Status Update Error',
            'All selected items must have the same status to progress.'
          )
        } else if (this.containsFinalizedItems(items)) {
          newErrorDialog('Status Update Error', 'Cannot update Completed or Canceled Items.')
        } else if (items[0].hasStatusReviewBlocked) {
          newErrorDialog('Status Update Error', 'Cannot update Items in an Error State.')
        } else if (items[0].hasStatusUserReview) {
          newErrorDialog('Status Update Error', 'Cannot batch update Items that require review.')
        } else {
          if (statusObject.includesReadyForDispatchItem) {
            newErrorDialog(
              'Status Update Error',
              'Cannot update order items past ready for dispatch.'
            )
            return
          } else if (
            statusObject.includesItemRequiringCustomization &&
            statusObject.includesItemNotRequiringCustomization
          ) {
            newErrorDialog(
              'Status Update Error',
              'Some selected items still require customization.'
            )
            return
          } else if (statusObject.includesItemRequiringCustomization) {
            if (
              await confirmDialog(
                'Update ' + items.length + ' Items',
                'Update Items as customized and mark as STAGED_FOR_NESTING?'
              )
            ) {
              showSpinner(true, items.length)
              setGlobalUpdatingFlag(true)
              for (const item of items) {
                await item.customizationPerformed()
              }
              showSpinner(false)
              setGlobalUpdatingFlag(false)
              itemsUpdated()
            }
          } else if (statusObject.includesStockCheckItem) {
            this.openBatchStockCheckDialog(items)
            return
          } else if (statusObject.includesSkuResolutionFailed) {
            this.openBatchSkuResolutionDialog(items)
          } else if (
            await confirmDialog(
              'Update ' + items.length + ' Items',
              'Update Statuses from ' + items[0].status + ' to ' + items[0].nextStatus() + '?'
            )
          ) {
            showSpinner(true, items.length)
            setGlobalUpdatingFlag(true)
            for (const item of items) {
              await item.progress(items[0].status as LocalOrderItemStatusEnum)
            }
            showSpinner(false)
            setGlobalUpdatingFlag(false)
            itemsUpdated()
          }
        }
      } catch (e) {
        errorHandler(e)
      }
    },
    resolveSkuResolutionFailure(item: LocalOrderItem) {
      this.openItemDialog(item, true)
    },
    downloadDesignFile(item: LocalOrderItem) {
      api.downloadDesignFile(item)
    },
    updateMaterialsFilter(existingFilter: string | undefined): void {
      const materialIndex = this.filters.findIndex((f) => f.objectPropertyKeyName === 'material')
      const materialLabels = this.materials.map((m) => m.label || '')
      let newMatFilter = new Filter('material', materialLabels, 0)
      const existingIndex: number = newMatFilter.propertyFilterValues.indexOf(existingFilter || '')
      const isAllMaterialFilter = existingFilter?.toLowerCase() === 'all materials'
      if (materialIndex > -1) {
        if (existingFilter && !isAllMaterialFilter && existingIndex === -1) {
          newMatFilter.propertyFilterValues.push(existingFilter)
        }
        this.filters[materialIndex] = newMatFilter
      } else {
        this.filters.push(newMatFilter)
      }
    },
    error(error: any) {
      errorHandler(error)
    },
    getCollapsableRowsNotesIconObject(): CollapsableRowGroupIcon {
      return {
        displayCheck: (tableRows: LocalOrderItem[]) => {
          const notes = tableRows.map((r) => r.manufacturing_notes).filter((n) => n && n.length > 0)
          return tableRows.length > 0 && notes.length > 0
        },
        iconComponent: ChatBubbleLeftEllipsisIcon,
        iconClass:
          'w-6 h-6 inline-block stroke-[0.125rem] text-orange-500 drop-shadow-[0.15rem_0.15rem_0.075rem_rgba(0,0,0,0.2)]',
        getToolTip: (tableRows: LocalOrderItem[]) => {
          const notes = tableRows.map((r) => r.manufacturing_notes).filter((n) => n && n.length > 0)
          return `${notes.length} ${notes.length == 1 ? ' Row' : ' Rows'} With Manufacturing Notes`
        }
      }
    },
    processFieldFunction(fieldValue: any, columnName: string, row?: any) {
      // Formats expected objects as readable string(s)
      const column = columnName.toLowerCase().replace(/_/g, ' ')
      if (['created', 'date', 'updated'].includes(column)) {
        // Is date
        if ('updated' === column) return processHumanReadableDate(fieldValue)
        return processHumanReadableDate(fieldValue, true)
      }

      if (column === 'status') {
        return { component: StatusLabelComponent, propObject: { label: fieldValue, shrink: true } }
      }

      if (column === 'sku') {
        const item: LocalOrderItem | undefined = row as LocalOrderItem
        if (item.hasStatusSkuResolutionFailed && item.unresolved_sku) {
          return {
            component: TableIconComponent,
            propObject: {
              before: item.unresolved_sku.length > 0 ? item.unresolved_sku : 'No SKU',
              textClass: 'text-red-600 inline-block w-6 h-6 font-medium w-full'
            }
          }
        } else {
          return this.skus.find((sku) => sku.id === fieldValue)?.label
        }
      }

      if (column === 'requires post processing') {
        return fieldValue === true || (fieldValue && fieldValue.toString().toLowerCase() === 'yes')
          ? {
              component: TableIconComponent,
              propObject: {
                iconComponent: CheckCircleIcon,
                tooltip: fieldValue,
                class: 'text-lime-500 inline-block w-6 h-6'
              }
            }
          : {
              component: TableIconComponent,
              propObject: {
                iconComponent: XCircleIcon,
                tooltip: fieldValue
              }
            }
      }

      if (['notes', 'post processing notes', 'manufacturing notes'].includes(column)) {
        // Notes Field, replace with icon
        if (fieldValue && fieldValue.length > 0) {
          return {
            component: TableIconComponent,
            propObject: {
              iconComponent: ChatBubbleLeftEllipsisIcon,
              tooltip: fieldValue,
              class:
                'w-7 h-7 inline-block stroke-[0.125rem] text-orange-500 drop-shadow-[0.15rem_0.15rem_0.075rem_rgba(0,0,0,0.2)]'
            }
          }
        } else {
          return ''
        }
      }

      return fieldValue
    },
    updateLocalOrderItems() {
      if (!getGlobalUpdatingFlag()) this.triggerTableRefresh()
    },
    async retrieveRelevantSkus(items: LocalOrderItem[]) {
      const uniqueItemSkuIDs: number[] = items
        .map((item: LocalOrderItem) => item.sku || -1)
        .filter((sku, index, self) => self.indexOf(sku) === index && sku > -1)
      this.skus = await api.getSkusIn(uniqueItemSkuIDs)
    }
  },
  created() {
    createUpdatedOrderListener(this.updateLocalOrderItems.bind(this))
    createUpdateditemsListener(this.updateLocalOrderItems.bind(this))
    createUpdateditemListener(this.updateLocalOrderItems.bind(this))
  },
  components: {
    PageHeader,
    TableComponent
  }
})
</script>
